/* eslint-disable @typescript-eslint/no-unused-vars */
import LegislativeEventsSearchParams from '@enview/interface/events/LegislativeEventsSearchParams';
import { Hearing } from '@enview/interface/types/bills/Bill';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { JurisdictionAPI } from '../../../api';
import { convertIdToAbbreviatedTitle } from '../../../helpers/BillHelper';
import { getDefaultPage, getPageForQuery } from '../../../helpers/TableHelper';
import JurisdictionHearingStatusBadge from '../JurisdictionHearingStatusBadge';
import JurisdictionHearingsFilters from './JurisdictionHearingsFilters';
import { Thunk } from '../../../dux/@types';
import { useDispatch } from 'react-redux';
import { checkPermission } from '../../../dux';
import { g as abilityGlossary } from '../../../config/ability';
import { SortBy, SortByParam, SortOrder } from '../../../models/SortOrder';
import { SortableDataTableColumn } from '../../LegislativeTracking/TabularBillSearchResults';
import { isEqual } from 'lodash-es';

type JurisdictionHearingsProps = {
  jurisAbbr?: string;
};

const JurisdictionHearings: React.FC<JurisdictionHearingsProps> = (props) => {
  const { jurisAbbr = '' } = props;

  const [sort, setSort] = useState<SortOrder>(
    new SortOrder(SortBy.RECENT_ACTIVITY, false),
  );

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, 10),
    offset: withDefault(NumberParam, 0),
    startDateRange: withDefault(DateParam, undefined),
    endDateRange: withDefault(DateParam, undefined),
    orderColumn: withDefault(StringParam, SortByParam.LATEST),
    orderDirection: withDefault(StringParam, 'DESC'),
    cmteNames: withDefault(StringParam, undefined),
  });

  const dispatch = useDispatch();

  const canViewHearings = (): Thunk<boolean> => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.HEARINGS, true),
    );
  };

  const params: LegislativeEventsSearchParams = {
    ...query,
    orderDirection: query.orderDirection as 'ASC' | 'DESC',
    cmteNames: undefined,
  };

  params.cmteNames = query.cmteNames;
  const { data: hearings, isLoading } = JurisdictionAPI.endpoints.getEvents.useQuery({
    jurisAbbr,
    params,
  });
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const resetScroll = (): void => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  const columns: SortableDataTableColumn[] = useMemo(
    () => [
      {
        name: t('hearings.table.status'),
        selector: 'status',
        sortable: true,
        sortKey: SortBy.HEARING_STATUS,
        maxWidth: '160px',
        style: {
          padding: '0px 0px 0px 10px',
        },
        cell: (hearing: Hearing) => (
          <JurisdictionHearingStatusBadge hearing={hearing} />
        ),
        minWidth: '125px',
      },
      {
        name: t('hearings.table.committee'),
        cell: (hearing: Hearing) => {
          const links = hearing.relatedCommittees?.map((committee) => (
            <Link
              className="table-link"
              key={`committee-table-link-${committee.id}`}
              to={`/committee/${committee.id}`}
            >
              {committee.name}
            </Link>
          ));
          return (
            <div className="committee-link-container">
              {links?.length ? links : hearing.eventData.name}
            </div>
          );
        },
      },
      {
        name: t('hearings.table.date'),
        sortKey: SortBy.RECENT_ACTIVITY,
        cell: (hearing: Hearing) => {
          const startDateTime = new Date(hearing.start);
          const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZoneName: 'short',
          };

          const formattedDate = startDateTime.toLocaleDateString('en-US', options);
          return formattedDate;
        },
        selector: 'start',
        sortable: true,
      },
      {
        name: t('hearings.table.location'),
        ignoreRowClick: true,
        cell: (hearing: Hearing) => hearing.eventData?.location?.name ?? '',
      },
      {
        name: t('hearings.table.bill'),
        style: {
          paddingLeft: '0px',
        },
        cell: (hearing: Hearing) => {
          const links = hearing.relatedBills?.map((bill) => {
            const title = convertIdToAbbreviatedTitle(bill.id);
            return (
              <li>
                <Link
                  className="table-link"
                  key={`bill-table-link-${bill.id}`}
                  target="_blank"
                  to={`/legislative-tracking/bill/details/${bill.id}`}
                >
                  {title}
                </Link>
              </li>
            );
          });
          return <ul className="table-link-container">{links}</ul>;
        },
      },
      {
        name: t('hearings.table.description'),
        ignoreRowClick: true,
        minWidth: '200px',
        cell: (hearing: Hearing) => (
          <div className="truncate-box">{hearing.eventData?.description ?? ''}</div>
        ),
      },
      {
        name: t('hearings.table.source'),
        ignoreRowClick: true,
        cell: (hearing: Hearing) => {
          if (!hearing.eventData.sources?.length) return '';
          const sourceUrl = hearing.eventData.sources[0].url;
          return (
            <a className="source-link" href={sourceUrl} target="_blank">
              {t('hearings.table.sourceLink')}
            </a>
          );
        },
      },
    ],
    [],
  );

  const getDefaultSort = useCallback((): {
    selector: string;
    direction: 'asc' | 'desc';
  } => {
    const sorderOrderKey = sort?.sortBy ?? SortBy.RECENT_ACTIVITY;
    const selector =
      columns
        .find((column) => column.sortKey === sorderOrderKey)
        ?.selector?.toString() ?? 'action_date';
    return { selector, direction: sort?.isReversed ? 'desc' : 'asc' };
  }, [sort, columns]);

  const handleSortChange = useCallback(
    (column: SortableDataTableColumn, sortDirection: 'desc' | 'asc') => {
      const sortColumn = column.sortKey ?? SortBy.RECENT_ACTIVITY;
      const sortOrder = new SortOrder(sortColumn, sortDirection === 'desc');
      if (isEqual(sort, sortOrder)) return;
      setSort(sortOrder);
      setQuery({
        orderColumn: sortOrder.getQueryParam(),
      });
    },
    [sort, setQuery],
  );

  const handlePageChange = useCallback((page: number) => {
    setQuery(getPageForQuery(page, query));
  }, []);

  const handlePageSizeChange = useCallback((currentRowsPerPage: number) => {
    setQuery({ limit: currentRowsPerPage, offset: 0 });
  }, []);

  const customStyles = {
    cells: {
      style: {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
  };

  return (
    <div className="container-fluid committe-view p-0">
      {canViewHearings() ? (
        <>
          <div className="committee-header">
            <div className="row m-4">
              <h2>{t('hearings.header', { count: hearings?.count || 0 })}</h2>
            </div>
          </div>
          <div>
            <JurisdictionHearingsFilters
              jurisAbbr={jurisAbbr}
              query={query}
              resetScroll={resetScroll}
              setQuery={setQuery}
            />
            <div className="table hearings-table">
              {isLoading && (
                <div className="table-spinner-overlay">
                  <Spinner animation="border" role="status" />
                </div>
              )}
              <DataTable
                columns={columns}
                customStyles={customStyles}
                data={hearings?.data ?? []}
                defaultSortAsc={getDefaultSort().direction === 'asc'}
                defaultSortField={getDefaultSort().selector}
                disabled={isLoading}
                noContextMenu
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePageSizeChange}
                onSort={handleSortChange}
                pagination
                paginationDefaultPage={getDefaultPage(query)}
                paginationPerPage={query.limit}
                paginationRowsPerPageOptions={[10, 20, 50]}
                paginationServer
                paginationTotalRows={hearings?.count}
                responsive
                sortIcon={<FontAwesomeIcon icon={faCaretUp} />}
                sortServer
                striped
              />
            </div>
          </div>
        </>
      ) : (
        <div className="m-4">
          <Trans
            components={{
              hearingsLink: <a href={t('urls.hearings')} target="_blank" />,
              gate: <p className="tooltip-header" />,
            }}
            i18nKey="featureGating.tooltipText.newHearingsPage"
          />
        </div>
      )}
    </div>
  );
};

export default JurisdictionHearings;
